import { GET_USER_PROFILE_DETAILS } from 'containers/App/queries';
import { filter } from 'lodash';
import Mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from 'utils/data/mixpanelEvents';
import * as Sentry from '@sentry/react';
import fetchData from 'utils/fetchData';
import { catchError } from 'utils/helpers';
import { version } from '../version';

export const getAuthUser = async mixpanelEvent => {
  try {
    return fetchData({
      queryString: GET_USER_PROFILE_DETAILS,
      queryKey: 'auth_user_me',
      queryVariables: {},
      forceRefresh: false,
    });
  } catch (error) {
    return catchError(
      Error(`auth_user_me api failed when calling for mixpanel event: ${mixpanelEvent} error. Error: ${error}`),
    );
  }
};

export const trackEvent = async ({ event, options }) => {
  const user = await getAuthUser(event);
  const userEmail = user?.email ?? '';
  if (user?.id && typeof userEmail === 'string' && !userEmail.includes('talview.com')) {
    const defaultOptions = {
      Application: 'wft-client',
      'Application Version': version,
      'Application Environment': process.env.APP_ENV,
      'Organization Name': user?.tenant?.name,
      'Organization Id': user?.tenant?.id,
      'User Id': user?.id,
      Authenticated: true,
      ...options,
    };
    if (defaultOptions['Organization Name'] === '' || defaultOptions['Organization Id'] === '') {
      Sentry.captureMessage(`Organization Name or Organization id is missing when logging ${event} event`);
    }
    if (window.navigator.userAgent.indexOf('Edg') > -1) {
      defaultOptions.$browser = 'Microsoft Edge';
    }
    if (process.env.STORYBOOK_APP_ENV !== 'storybook') {
      Mixpanel.track(event, { ...defaultOptions });
    }
  } else if (process.env.STORYBOOK_APP_ENV !== 'storybook' && !user?.id) {
    const defaultOptions = {
      Application: 'wft-client',
      'Application Version': version,
      'Application Environment': process.env.APP_ENV,
      Authenticated: false,
      'User payload': user,
      ...options,
    };
    Mixpanel.track(`unAuthenticated-${event}`, { ...defaultOptions });
  }
};

export const scheduleAction = ({ options }) => {
  const { payload, additionalContext } = options;
  if (additionalContext?.location === 'SelfScheduleModal') {
    trackEvent({
      event: mixpanelEvents.SELF_SCHEDULE_MODAL_CLICK,
      options: {
        'Count of the Candidates': additionalContext?.recordsWithoutEvaluators?.length,
        'Time taken for submission': additionalContext?.timeDuration,
      },
    });
  }
  const staticSchedulePayloadPresent = filter(payload, p => !!(p?.start_date && p?.end_date));
  if (staticSchedulePayloadPresent.length) {
    trackEvent({
      event: mixpanelEvents.STATIC_SCHEDULE_CLICK,
      options: {
        'Count of the Candidates': staticSchedulePayloadPresent.length,
        'Time taken for submission': additionalContext?.timeDuration,
      },
    });
  }
  const selfSchedulePayloadPresent = filter(payload, p => !(p?.start_date && p?.end_date));
  if (selfSchedulePayloadPresent.length) {
    trackEvent({
      event: mixpanelEvents.SELF_SCHEDULE_CLICK,
      options: {
        'Count of the Candidates': selfSchedulePayloadPresent.length,
        'Time taken for submission': additionalContext?.timeDuration,
      },
    });
  }
};

export const selfScheduleSuccessfulAction = ({ payload }) => {
  const { successCount, errorCount } = payload;
  trackEvent({
    event: mixpanelEvents.SCHEDULE_CLICK_SCHEDULE_SUCCESS,
    options: {
      'Success Count': successCount,
      'Failure Count': errorCount,
    },
  });
};

export const cancelInterviewAction = ({ options }) => {
  // cancel click side panel or cancel click modal
  const { payload, additionalContext } = options;
  if (additionalContext?.location === 'sidePanel') {
    trackEvent({
      event: mixpanelEvents.PROCEED_CANCEL_SIDE_PANEL,
      options: {
        'Count of the Candidates': payload?.length,
      },
    });
  } else {
    trackEvent({
      event: mixpanelEvents.PROCEED_CANCEL_CLICK_MODAL,
      options: {
        'Count of the Candidates': payload?.length,
      },
    });
  }
};

export const cancelModalOpen = ({ payload }) => {
  trackEvent({
    event: mixpanelEvents.CANCEL_MODAL_OPEN,
    options: {
      'Count of the Candidates': payload?.length,
    },
  });
};

export const cancelSidePanelOpen = ({ payload }) => {
  trackEvent({
    event: mixpanelEvents.CANCEL_SIDE_PANEL_OPEN,
    options: {
      'Count of the Candidates': payload?.length,
    },
  });
};

export const cancelModalNoClick = ({ payload }) => {
  trackEvent({
    event: mixpanelEvents.NO_CLICK_CANCEL_INT_MODAL,
    options: {
      'Count of the Candidates': payload?.length,
    },
  });
};

export const cancelSidePanelNoClick = ({ payload }) => {
  trackEvent({
    event: mixpanelEvents.NO_CANCEL_CLICK_SIDE_PANEL,
    options: {
      'Count of the Candidates': payload?.length,
    },
  });
};

export const rescheduleSidePanelClick = ({ payload }) => {
  trackEvent({
    event: mixpanelEvents.RESCHEDULE_CLICK_SIDE_PANEL,
    options: {
      'Count of the Candidates': payload?.length,
    },
  });
};

export const rescheduleProceedClick = ({ payload }) => {
  trackEvent({
    event: mixpanelEvents.PROCEED_CLICK_RESCHEDULE,
    options: {
      'Count of the Candidates': payload?.length,
    },
  });
};

export const uploadFileAction = ({ payload }) => {
  trackEvent({
    event: mixpanelEvents.UPLOAD_CLICK_SIDE_PANEL,
    options: {
      'Count of the Candidates': payload?.length,
    },
  });
};

export const scheduleClickAction = ({ payload }) => {
  trackEvent({
    event: mixpanelEvents.SCHEDULE_CLICK_DASHBOARD,
    options: {
      'Count of the Candidates': payload?.length,
    },
  });
};

export const interviewerMeetingDecline = () => {
  trackEvent({
    event: mixpanelEvents.MEETING_INTERVIEWER_DECLINED_STARTED,
    // options: {
    //   'Count of the Candidates': payload?.length,
    // },
  });
};

export const interviewerMeetingDeclineCompleted = ({ options }) => {
  const { payload } = options;
  const { proposed_panel_id, reason } = payload;
  trackEvent({
    event: mixpanelEvents.MEETING_INTERVIEWER_DECLINED_COMPLETED,
    options: {
      'Reason for Decline': reason,
      'Choose Alternate Panel': proposed_panel_id ? 'YES' : 'NO',
    },
  });
};

export const proposeNewTimeAction = ({ options }) => {
  const { additionalContext } = options;
  trackEvent({
    event: mixpanelEvents.PROPOSE_NEW_TIME_EMAIL_INTERVIEWER,
    options: {
      'Meeting Id': additionalContext?.meeting_id,
      'Meeting Participant Id': additionalContext?.participant_id,
      'Interview Platform': additionalContext?.provider,
      'Role of Participant': 'Interviewer',
    },
  });
};

export const interviewerDeclineCandidateProposedTime = ({ options }) => {
  const { additionalContext } = options;
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_DECLINED_CANDIDATE_PROPOSED_TIME,
    options: {
      'Meeting Id': additionalContext?.meeting_id,
      'Meeting Participant Id': additionalContext?.participant_id,
      'Interview Platform': additionalContext?.provider,
    },
  });
};

export const interviewerAcceptCandidateProposedTime = ({ options }) => {
  const { payload, additionalContext } = options;
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_ACCEPT_CANDIDATE_PROPOSED_TIME,
    options: {
      'Meeting Id': payload?.meeting_id,
      'Meeting Participant Id': additionalContext?.participant_id,
      'Interview Platform': additionalContext?.provider,
    },
  });
};

export const interviewerJoinedMeeting = ({ options }) => {
  const { payload } = options;
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_JOINED_MEETING,
    options: {
      'Meeting Id': payload?.meeting_id,
      'Meeting Participant Id': payload?.participant_id,
      'Interview Platform': payload?.provider,
      'Role of Participant': 'Interviewer',
    },
  });
};

export const interviewerAddSlot = ({ options }) => {
  const { payload } = options;
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_ADDED_SLOT,
    options: {
      Timezone: payload.availability.timezone,
    },
  });
};

export const interviewerDeleteSlot = ({ options }) => {
  const { additionalContext } = options;
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_DELETED_SLOT,
    options: {
      Timezone: additionalContext.timezone,
    },
  });
};

export const workflowCreateAction = ({ options }) => {
  const { payload } = options;
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_CREATED_WORKFLOW,
    options: {
      'Template Name': payload?.template_name,
    },
  });
};

export const workflowTemplateViewAction = ({ options }) => {
  const { payload } = options;
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_VIEWED_WORKFLOW,
    options: {
      'Template Name': payload?.template_name,
    },
  });
};

export const setAvailabilityViewEvent = () => {
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_SET_AVAILABILITY_VIEW,
  });
};

export const navigatedCalendarView = ({ payload }) => {
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_NAVIGATED_CALENDAR_VIEW,
    options: {
      Timezone: payload?.value,
    },
  });
};

export const addingOverrideSlot = () => {
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_ADDING_OVERRIDE_SLOT,
  });
};

export const interviewerAddedHoliday = () => {
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_ADDED_HOLIDAY,
  });
};

export const recruiterBeginWorkflowCreation = () => {
  trackEvent({
    event: mixpanelEvents.RECRUITER_BEGIN_WORKFLOW_CREATION,
  });
};

export const interviewerAddedOverrideSlot = () => {
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_ADDED_OVERRIDE_SLOT,
  });
};

export const interviewerEditedTimeSlot = ({ payload }) => {
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_EDITED_TIME_SLOT,
    options: {
      Timezone: payload?.value,
    },
  });
};

export const recruiterSelectedWorkflowTemplate = ({ options }) => {
  const { payload } = options;
  trackEvent({
    event: mixpanelEvents.RECRUITER_SELECTED_WORKFLOW_TEMPLATE,
    options: {
      'Template Name': payload?.template_name,
    },
  });
};

export const interviewerCopiedSlot = ({ options }) => {
  const { payload, additionalContext } = options;
  trackEvent({
    event: mixpanelEvents.INTERVIEWER_COPIED_SLOT,
    options: {
      Timezone: payload?.value,
      'Number of Weekdays Selected': additionalContext?.length,
    },
  });
};

export const recruiterRenamedWorkflowTemplate = ({ payload }) => {
  trackEvent({
    event: mixpanelEvents.RECRUITER_RENAMED_WORKFLOW_TEMPLATE,
    options: {
      'Template Name': payload?.templateName,
      'Part of Template Renamed': payload?.templateName,
    },
  });
};
export const recruiterRenamedWorkflowTemplate1 = options => {
  const { payload, additionalContext } = options;
  trackEvent({
    event: mixpanelEvents.RECRUITER_RENAMED_WORKFLOW_TEMPLATE,
    options: {
      'Template Name': payload?.workflowName,
      'Part of Template Renamed': additionalContext?.name,
    },
  });
};

export const panelJoinEvent = options => {
  const { payload } = options;
  trackEvent({
    event: mixpanelEvents.MEETIING_INTERVIEWER_EMAIL_INVITE_JOINED,
    options: {
      'Meeting Id': payload?.participantId,
      'Meeting Participant Id': payload?.meetingId,
    },
  });
};
